import React from 'react';

import { useIntl } from '../../plugins/publicis-gatsby-plugin-i18n';
import Logo from '../assets/images/logo.inline.svg';
import MediaPageHeaderDesign from '../assets/images/mediapage-design-header.inline.svg';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Seo from '../components/Seo/Seo';
import Layout from '../Layout';
import { Button } from '../Molecules/Button/Button';
import { TitlePage } from '../Molecules/TitlePage/TitlePage';

import './styles.scss';

const classNames = require('classnames');

// markup
const NotFoundPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'error.title' })} />
      <div className={classNames('page_template', 'page_error')}>
        <MediaPageHeaderDesign className="media_top_design" />
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              currentPage: {
                title: intl.formatMessage({ id: 'error.title' }),
                url: '',
              },
              locale: intl.locale,
            }}
          />
          <div className='container_error'>
            <div className='logo_container'>
              <Logo className="logo_colas" />
            </div>
            <div className='error_text'>
              <TitlePage color="color_dark_bleu" title="404" />
              <p className="background_text">Oups !</p>
            </div>
            <p className='title_error' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'error.errorText' }) }}></p>
            <div className="btn_container">
              <Button
                label={intl.formatMessage({ id: 'error.button.label' })}
                icon="arrowright"
                link={`/${intl.locale}`}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout >

  );
};

export default NotFoundPage;
